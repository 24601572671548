@import '../../styles/variables';
@import '../../styles/mixins';
.footer {
  background-color: $primary_color;
  padding-top: 57px;
  @include media-breakpoint-down(md) {
    padding-top: 30px;
  }
  .quick-links {
    list-style: none;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
      padding: 0;
      margin: 0;
    }
    li {
      color: #fff;
      font-family: $fontDescription;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 110% */
      @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 26px;
      }
      a {
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  .divider {
    background-color: #fff;
    opacity: 1;
    height: 15px !important;
    align-self: center !important;
    margin: 0 10px !important;
    width: 1px;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .logo {
    margin: 31px 0;
    text-align: center;
    @include media-breakpoint-down(md) {
      margin-top: 0;
      margin-bottom: 14px;
    }
    svg {
      width: 100%;
      @include media-breakpoint-down(md) {
        width: 60%;
      }
    }
  }
  .copy-right {
    border-top: 1px solid #ffffff51;
    padding: 17px 0;
    color: #fff;
    display: flex;
    justify-content: space-between;
    font-family: $fontDescription;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .disclaimer {
    padding: 17px 0;
    color: #fff;
    display: flex;
    justify-content: space-between;
    font-family: $fontDescription;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .footer-pragraph {
    color: #fff;
    font-family: $TTHovesThin;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 29px;
    text-align: center;
    @include media-breakpoint-down(md) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
