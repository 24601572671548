* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

@import './styles/variables';
@import './styles/mixins';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'Typo Hoop Demo';
  src: url(./fonts/RPFonts/Typo_Hoop_Bold_Demo.otf) format('opentype');
}
@font-face {
  font-family: 'Typo Hoop Hairline Demo';
  src: url(./fonts/RPFonts/Typo_Hoop_Hairline_Demo.otf) format('opentype');
}
@font-face {
  font-family: 'Typo Hoop Light Demo';
  src: url(./fonts/RPFonts/Typo_Hoop_Light_Demo.otf) format('opentype');
}
@font-face {
  font-family: 'Typo Hoop Regular Demo';
  src: url(./fonts/RPFonts/Typo_Hoop_Regular_Demo.otf) format('opentype');
}

@font-face {
  font-family: 'TTHoves Black';
  src: url(./fonts/RPFonts/TTHoves-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'TTHoves Bold';
  src: url(./fonts/RPFonts/TTHoves-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'TTHoves Thin';
  src: url(./fonts/RPFonts/TTHoves-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'TTHoves-Medium';
  src: url(./fonts/RPFonts/TTHoves-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'TTHoves-Italic';
  src: url(./fonts/RPFonts/TTHoves-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Gotham Black';
  src: url(./fonts/Gotham_font/Gotham-Black.otf) format('truetype');
}

@font-face {
  font-family: 'Gotham-BlackItalic';
  src: url(./fonts/Gotham_font/Gotham-BlackItalic.otf) format('truetype');
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url(./fonts/Gotham_font/Gotham-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'Gotham-BoldItalic';
  src: url(./fonts/Gotham_font/Gotham-BoldItalic.otf) format('truetype');
}

@font-face {
  font-family: 'Gotham-Book';
  src: url(./fonts/Gotham_font/Gotham-Book.otf) format('truetype');
}

@font-face {
  font-family: 'Termina Test';
  src: url('./fonts/termina/Termina-Light.woff2') format('woff2'),
      url('./fonts/termina/Termina-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Termina Test';
  src: url('./fonts/termina/Termina-Bold.woff2') format('woff2'),
      url('./fonts/termina/Termina-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Termina Test';
  src: url('./fonts/termina/Termina-Thin.woff2') format('woff2'),
      url('./fonts/termina/Termina-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Termina Test';
  src: url('./fonts/termina/Termina-Regular.woff2') format('woff2'),
      url('./fonts/termina/Termina-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Termina Test Extra';
  src: url('./fonts/termina/Termina-ExtraLight.woff2') format('woff2'),
      url('./fonts/termina/Termina-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Termina Test';
  src: url('./fonts/termina/Termina-Heavy.woff2') format('woff2'),
      url('./fonts/termina/Termina-Heavy.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Termina Test';
  src: url('./fonts/termina/Termina-Black.woff2') format('woff2'),
      url('./fonts/termina/Termina-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Termina Test';
  src: url('./fonts/termina/Termina-Medium.woff2') format('woff2'),
      url('./fonts/termina/Termina-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Termina Test Demi';
  src: url('./fonts/termina/Termina-Demi.woff2') format('woff2'),
      url('./fonts/termina/Termina-Demi.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}



.capitalize-first-letter::first-letter {
  text-transform: uppercase;
}

.swal2-container {
  z-index: 999999999;
}

/*================ global-css-for-dashboard-tables ================*/
.search-box {
  form {
    border-radius: 100px;
    background: #fff;
    box-shadow: 0px 0px 30px 0px #ebebeb;
    padding: 3px 5px 3px 15px;
  }
}
.table-bordered {
  background: #fff;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  overflow: hidden;
  box-shadow: 0 0 30px 0 #ebebeb;
  font-family: 'TTHoves-Medium';
  thead {
    tr {
      th {
        background: #004ccf;
        color: #fff;
        border: none;
        padding: 18px 0px 18px 20px;
        font-weight: 500;
        &:last-child {
          padding-right: 20px;
        }
      }
    }
  }
  tbody {
    tr {
      border-color: #dadada;
      td {
        border: none;
        padding: 18px 0px 18px 20px;
        font-size: 15px;
        font-family: 'TTHoves-Medium';
        &:nth-last-child(2) {
          button {
            white-space: nowrap;
          }
        }
        &:last-child {
          white-space: nowrap;
          display: flex;
          gap: 6px;
          padding-right: 13px;
          button:nth-child(1) {
            background: #f15f3d;
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
            color: #fff;
            padding: 5px;
            svg {
              width: 0.8em;
              height: 0.8em;
              path {
                fill: #fff;
              }
            }
          }
          button:nth-child(2) {
            background: #f3c42b;
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
            color: #fff;
            padding: 5px;
            svg {
              width: 0.8em;
              height: 0.8em;
            }
          }
          button:nth-child(3) {
            background: #0c4ccf;
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
            color: #fff;
            padding: 5px;
            svg {
              width: 0.8em;
              height: 0.8em;
            }
          }
        }
      }
      &:last-child {
        border: none;
      }
    }
  }
}
.MuiButton-outlinedPrimary {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}
.MuiPagination-ul .Mui-selected {
  background-color: #f7acac !important;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}
.MuiPaginationItem-page {
  font-family: 'TTHoves-Medium' !important;
}
/*=============== /global-css-for-dashboard-tables ================*/
