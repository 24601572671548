@import '../../styles/variables';
@import '../../styles/mixins';
.cart-wrapper {
  .content {
    width: 444px;
    // padding-bottom: 200px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(sm) {
      width: calc(100vw - 30px);
    }
    .cart-header {
      display: flex;
      justify-content: space-between;
      padding: 25px 24px;
      border-bottom: 1px solid #d4d4d4;
      align-items: center;
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 1;
      .title {
        color: var(--Black, #0d0d0d);
        font-family: $fontTitle;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .cart-body {
      overflow-y: scroll;
      height: 100%;
    }
    .item-list {
      display: flex;
      padding: 25px 0 25px 0;
      margin: 20px;
      border-bottom: 1px solid #e3e1dd;
      align-items: center;
      justify-content: space-between;
      @include media-breakpoint-down(md) {
        padding: 0px 0 6px 0;
        margin: 16px;
      }

      .reward-logo {
        min-width: 96px;
        max-width: 96px;
        height: 96px;
        border-radius: 8px;
        border: 1px solid #e3e1dd;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-down(md) {
          min-width: 46px;
          max-width: 46px;
          height: 46px;
        }
        .logo-wrap {
          padding: 10px;
          box-sizing: border-box;
        }
      }
      .reward-intro {
        padding: 0 15px;
        .rew-title {
          color: $textPrimary;

          /* body-text */
          font-family: $fontDescription;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 137.5% */
          @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 16px;
          }
        }
        .rew-desc {
          color: #666;
          font-family: $fontDescription;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
          @include media-breakpoint-down(md) {
            font-size: 12px;
            line-height: 14px;
            margin-top: 5px;
          }
        }
      }
      .action {
        .price {
          padding: 8px;
          color: $textPrimary;
          text-align: right;

          /* body-text */
          font-family: $fontDescription;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 137.5% */
        }
      }
    }
    .cart-footer {
      background-color: #f8f8f8;
      padding: 20px 24px;
      // position: fixed;
      // position: absolute;
      // bottom: 0;
      // left: 0;
      // right: 0;
      // width: 444px;
      @include media-breakpoint-down(md) {
        // position: sticky;
      }
      .label {
        color: #0d0d0d;
        text-align: right;
        font-family: $fontDescription;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 137.5% */
        margin: 5px 0;
      }
      .total-price {
        color: #0d0d0d;
        text-align: right;
        font-family: $fontDescription;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 137.5% */
      }
      .pinkbutton {
        @include pinkButton;
        margin-top: 23px;
      }
    }
  }
}
